<template>
    <div id="apps">
        <!--    <div class="changePass clear">-->
        <!--      <div class="lab clear">-->
        <!--        <label>旧密码</label><font>:</font><input type="password" placeholder="请输入旧密码" v-model="oldpas"/>-->
        <!--      </div>-->
        <!--      <div class="lab clear">-->
        <!--        <label>新密码</label><font>:</font><input type="password" placeholder="请输入新密码" v-model="newpass1"/>-->
        <!--      </div>-->
        <!--      <div class="lab clear">-->
        <!--        <label>确认密码</label><font>:</font><input type="password" placeholder="请再次输入新密码" v-model="newpass2"/>-->
        <!--      </div>-->
        <!--      <button class="save" @click="save()">保存</button>-->
        <!--    </div>-->

        <van-form @submit="onSubmit">
            <van-field
                    v-model="oldpas"
                    type="password"
                    label="原密码"
                    placeholder="请输入原密码"
                    :rules="[{ required: true, message: '请输入原密码' }]"
            />
            <van-field
                    v-model="newpass1"
                    type="password"
                    label="新密码"
                    placeholder="请输入新密码"
                    :rules="[{ required: true, message: '请输入新密码' }]"
            />
            <van-field
                    v-model="newpass2"
                    type="password"
                    label="确认密码"
                    placeholder="请确认密码"
                    :rules="[{ required: true, message: '请确认密码' }]"
            />
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit" :loading="submitLoading" style="background: linear-gradient(to right, #F05C4A, #F98E49);border: 0">
                    提交
                </van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
  import {Toast} from "vant";
  import {changePass} from "@/api/user";

  export default {
    name: 'app',
    components: {},
    data () {
      return {
        oldpas: '',
        newpass1: '',
        newpass2: '',
        submitLoading: false,
      }
    },
    methods: {
      onSubmit(values) {
        if (this.newpass1 !== this.newpass2) {
          Toast('两次输入密码不一致');
          return;
        }
        this.submitLoading = true;
        changePass({
          newPassword: this.newpass1,
          oldPassword: this.oldpas
        }).then(data => {
          if (data.success == true) {
            Toast('修改密码成功')
            this.$store.dispatch('Logout').then(() => {
              this.$router.push('/login')
            })

          } else {
            Toast(data.msg)
          }
          console.log(data)
        }).finally(() => {
          this.submitLoading = false
        })
      }
    }
  }
</script>

<style>
    * {
        margin: 0;
        padding: 0;
    }

    .clear {
        clear: both;
        overflow: hidden;
    }

    body {
        height: 100vh;
        background: #fff;
    }

    .changePass {
        width: 100%;
        margin-top: 2%;
    }

    .changePass .lab {
        width: 94%;
        margin: 5% auto 0 auto;
    }

    .changePass .lab label {
        margin-top: 3px;
        margin-left: 2%;
        position: relative;
        text-align: justify;
        vertical-align: top;
        width: 22%;
        display: inline-block;
        float: left;
        color: rgba(16, 16, 16, 100);
        font-size: 12px;
        height: 20px;
        line-height: 20px;
        font-family: 'PingFangSC-regular'
    }

    .changePass .lab font {
        float: left;
        line-height: 22px;
        display: inline-block;
    }

    .changePass .lab label::after {
        display: inline-block;
        width: 100%;
        content: '';
        height: 0;
    }

    .changePass .lab input {
        text-indent: 3%;
        display: inline-block;
        float: left;
        border: 1px solid rgba(187, 187, 187, 100);
        outline: none;
        font-size: 11px;
        -webkit-transform: scale(0.9);
        width: calc(74% - 1px);
        height: 24px;
        line-height: 24px;
    }

    input::-webkit-input-placeholder {
        font-size: 11px;
        color: #A8A8A8;
    }

    .changePass .save {
        display: block;
        border: solid 1px #BBBBBB;
        color: #101010;
        font-size: 13px;
        text-align: center;
        line-height: 28px;
        width: 90%;
        margin: 10% auto 0 auto;
        border-radius: 3px;
        background: #fff;
        outline: none;
    }

    .toast {
        position: fixed;
        z-index: 2000;
        left: 50%;
        top: 45%;
        transition: all .5s;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        text-align: center;
        border-radius: 5px;
        color: #FFF;
        background: rgba(17, 17, 17, 0.7);
        height: 45px;
        line-height: 45px;
        padding: 0 15px;
        max-width: 150px;
    }
</style>
